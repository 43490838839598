import get from 'lodash/get'

import tools from '@fielded/fs-api/lib/tools'
import { C_R, RESUPPLY } from '@fielded/fs-api/lib/shipment/tools/planning-types'
import { parse } from '@fielded/fs-api/lib/tools/smart-id'
import {
  isShipmentComplete,
  batchIdToProductId
} from './utils'

import { getOriginalQuantity } from '@fielded/fs-api/lib/shipment/utils/utils'
import { SHIPMENT_VERSIONS } from '@fielded/fs-api/lib/shipment/constants'
import keyBy from 'lodash/keyBy'

const getSnapshotQuantities = (shipmentHistory, snapshotDate) => {
  const snapShotQuantities = Object
    .values(shipmentHistory)
    .find((item) => item.createdAt === snapshotDate)

  return snapShotQuantities
}

export const hasProductQuantityDiscrepancies = (shipment, deliveryItems = []) => {
  if (deliveryItems.length) {
    return deliveryItems.some(item => item.delivered !== item.packed)
  }

  if (!shipment) {
    return false
  }

  const plannedQuantities = getPlannedQuantities(shipment)
  const availableCounts = get(plannedQuantities, 'counts')
  const receivedSnapshotDate = get(shipment, 'snapshotDates.received', '')
  const hasReceivedSnapshotDate = !!receivedSnapshotDate

  let hasQuantityDiscrepancies = false
  if (hasReceivedSnapshotDate && availableCounts) {
    const receivedQuantities = getSnapshotQuantities(shipment.history, receivedSnapshotDate)

    hasQuantityDiscrepancies = Object
      .entries(receivedQuantities.counts)
      .some(([productId, value]) => (
        availableCounts[productId] && availableCounts[productId].quantity !== value.quantity
      ))
  }

  return hasQuantityDiscrepancies
}

export const getPlannedQuantities = (shipment) => {
  if (!shipment) {
    return
  }

  const newSnapshotDate = get(shipment, 'snapshotDates.new', '')
  const packedSnapshotDate = get(shipment, 'snapshotDates.packed', '')
  const sentSnapshotDate = get(shipment, 'snapshotDates.sent', '')

  const hasPackedSnapshotDate = !!packedSnapshotDate

  const plannedQtySnapshotDate = (
    hasPackedSnapshotDate ? packedSnapshotDate : sentSnapshotDate || newSnapshotDate
  )

  const plannedQuantities = getSnapshotQuantities(shipment.history, plannedQtySnapshotDate)
  return plannedQuantities
}

const getPercentage = (percentage, value) => (percentage * value) / 100

export const makeDeliveryItems = ({
  shipment,
  report,
  productsById,
  includeReportItems = false,
  sortByName = false,
  plannedQuantities,
  customCountsObject
}) => {
  if (!shipment || !productsById) return null
  const itemsById = {}

  let countsObject = customCountsObject || shipment.counts
  const shouldReturnProducts = get(shipment, 'shouldReturnProducts', [])
  const returnProductsByProductId = keyBy(shouldReturnProducts, 'productId')

  for (const batchId of Object.keys(countsObject)) {
    const productId = batchIdToProductId(batchId)
    const batchParsed = parse(batchId)
    const { batchNo, manufacturer } = batchParsed
    const product = productsById[productId]
    if (!product) return

    const checked = get(countsObject, `${batchId}.checked`, false)
    let packed = get(countsObject, `${batchId}.quantity`, 0)

    if (plannedQuantities && plannedQuantities.counts) {
      packed = plannedQuantities.counts[batchId].quantity
    }

    let delivered = 0
    const ordered = plannedQuantities ? packed : countsObject[batchId].ordered

    // For some reason, already checked products appear as unchecked when the shipment is completed
    // so we need to check that as well to use this on package/delivery notes for completed shipments.
    if (checked || isShipmentComplete(shipment)) {
      const version = get(shipment, 'version', SHIPMENT_VERSIONS.VERSION_1)
      const deliveredQuantities = get(countsObject, `${batchId}.quantity`, 0)

      // For version 2 shipments we need to deduct the products that were returned
      if (version === SHIPMENT_VERSIONS.VERSION_2) {
        const returnedProduct = returnProductsByProductId[productId] || {}
        const returned = Math.abs(returnedProduct.quantity || 0)
        delivered = deliveredQuantities - returned
      } else {
        delivered = deliveredQuantities
      }

      if (itemsById[productId]) {
        delivered = delivered + itemsById[productId].delivered
      }
    }

    // get orderCreatedDate/snapshotdates and display product price for the latest snapshotdate
    const orderCreatedDate = get(shipment, 'orderCreatedDate')
    const snapshotDates = get(shipment, 'snapshotDates')

    const prices = get(product, 'prices')
    const buyPrices = get(product, 'buyPrices')
    const isResupply = shipment.planningType === RESUPPLY

    // if the shipment is resupply use buyPrices instead
    const referencePrices = isResupply ? buyPrices : prices
    const referenceDate = orderCreatedDate || snapshotDates.new
    let price = tools.product.getPrice(referencePrices, referenceDate)

    if (typeof price === 'undefined' || price === null || price === undefined) {
      price = tools.product.getPrice(referencePrices, snapshotDates.sent || snapshotDates.received)
    }

    const vatPercentage = getVatPercentage(product, shipment)
    const closing = get(report, `stock.${productId}.fields.field:standard-physical-count.amount`, 0)

    // this is VAT on the unit price of a product
    const deliveredValueWithoutVat = delivered * price
    const deliveredVatValue = getPercentage(vatPercentage, deliveredValueWithoutVat) || 0
    const deliveredValue = deliveredValueWithoutVat + deliveredVatValue

    // this is VAT on the unit price of a product
    const orderedValueWithoutVat = ordered * price
    const orderedVatValue = getPercentage(vatPercentage, orderedValueWithoutVat) || 0
    const orderedValue = orderedValueWithoutVat + orderedVatValue

    const balance = delivered + closing
    const balanceValueWithoutVat = balance * price
    const balanceVatValue = getPercentage(vatPercentage, balanceValueWithoutVat) || 0
    const balanceValue = balanceValueWithoutVat + balanceVatValue

    // Adjustments:
    const adjustmentDate = get(countsObject, `${batchId}.adjustment.date`, null)

    const subscriptionType = get(countsObject, `${batchId}.paymentType`, null)

    const prescription = get(countsObject, `${batchId}.prescription`, null)

    itemsById[productId] = {
      code: product.code,
      name: product.name,
      batchNo,
      manufacturer,
      closing,
      packed,
      delivered,
      balance,
      price,
      unitVat: getPercentage(vatPercentage, price),
      vat: balanceVatValue,
      adjustmentDate,
      deliveredValue,
      balanceValue,
      subscriptionType,
      fromShipment: true,
      prescription,
      ordered,
      orderedValue,
      basicUnit: product.basicUnit
    }

    if (shipment.planningType !== C_R) {
      continue
    }

    // Add some extra items for C&R:
    // ----------------------------
    let shipmentOriginalQuantity = getOriginalQuantity(shipment, batchId)
    let amountInLedger = get(report, `stock.${productId}.fields.field:shelflife-balance.amount`, 0)

    let originalQuantity = Math.min(shipmentOriginalQuantity, amountInLedger)

    // Only available when sales stats are enabled for C&R
    if (shipment.collection) {
      const stats = shipment.collection[batchId]
      if (stats) {
        shipmentOriginalQuantity = stats.quantity
        amountInLedger = stats.slBalance

        // In this case we have more up-to-date data from ledger and we don't have to speculate
        originalQuantity = shipmentOriginalQuantity
      }
    }

    // Only display these numbers if there is anything to invoice
    let quantityToCover = Math.max(originalQuantity - delivered, 0)
    let totalToPay = quantityToCover * price

    itemsById[productId] = {
      ...itemsById[productId],
      originalQuantity,
      totalToPay,
      quantityToCover
    }
  }

  if (includeReportItems && report && report.stock) {
    for (const productId of Object.keys(report.stock)) {
      const product = productsById[productId]
      const isInDelivery = itemsById[productId]
      if (product && !isInDelivery) {
        const {
          code,
          name,
          prices
        } = product
        // still use shipment created at so we don't get any strange discrepancies
        const vatPercentage = getVatPercentage(product, shipment)
        // get product value from delivery time
        // if changing this, please comment here why so we can track it
        const price = tools.product.getPrice(prices, shipment.createdAt)
        const unitVat = getPercentage(vatPercentage, price)
        const closing = get(report, `stock.${productId}.fields.field:standard-physical-count.amount`, 0)
        const balance = closing
        let balanceValue = balance * price
        const vat = getPercentage(vatPercentage, balanceValue)
        balanceValue += vat || 0

        itemsById[productId] = {
          code,
          name,
          closing,
          balance,
          price,
          unitVat,
          vat,
          balanceValue
        }
      }
    }
  }

  let items = Object.values(itemsById)
  if (sortByName) {
    items = items.sort((a, b) => a.name.localeCompare(b.name))
  } else {
    items = items.sort((a, b) => parseInt(a.code, 10) < parseInt(b.code, 10) ? -1 : 1)
  }

  return items
}

function getVatPercentage (product, shipment) {
  if (!product.vats || !product.vats.length) return

  return tools.product.getPrice(product.vats, shipment.createdAt, 'vat')
}
