import React, {Component} from 'react'
import {connect} from 'react-redux'
import flowRight from 'lodash/flowRight'
import { Loading } from '@fielded/shared-ui'
import { toast } from '@fielded/shared-ui/src/components/Page/Page'
import { isHeldLocation } from '@fielded/fs-api/lib/shipment/tools/is-held-location'
import { updateShipment } from '../../../root/reducers/shipments'
import { createSnapshot } from '../confirmation/confirmation-reducer'
import { loadPickList } from '../../../root/reducers/shipments/pick-list'
import withConfig from '../../../van-shared/hoc/withConfig'
import { withUser } from '../../../common/AuthenticationProvider'
import withShipment from './../common/WithShipmentWrapper'
import withMasterData from './../common/withMasterData'
import { withApi } from './../../../common/ApiProvider'
import {getShipmentBatches} from '@fielded/fs-api/lib/shipment/utils/utils'
import smartId from '@fielded/fs-api/lib/tools/smart-id'
import { isShipmentComplete, shipmentTitle, getDefaultReturnDestination } from '../common/utils'
import { formatDate, updateCurrentMasterData } from '../../../van-shared/utils'

import {
  transformToReturnShipment,
  getFractionChecked
} from '../../../common/utils/shipment'
import ReturnShipment from './ReturnShipment'
import { hasFeature } from '../../../van-shared/utils/features'
const canReturnShipment = (shipment) => shipment.origin && isHeldLocation(shipment.origin.id)

class ReturnShipmentContainer extends Component {
  state = {
    shipment: null,
    batches: []
  }

  async componentDidMount () {
    let {loadPickList, shipment, products} = this.props
    loadPickList(shipment, products)
  }

  componentDidUpdate (prevProps, prevState) {
    if (!this.state.shipment && this.props.shipment) {
      const {shipment, history, api, config} = this.props
      if (shipment.status !== 'received') {
        return history.push('/shipments/list')
      }
      const validateCanReturn = canReturnShipment(shipment)
      const returnShipment = transformToReturnShipment(api.state, shipment)
      const returnBatches = shipment.shouldReturnProducts
      // check if there is default auto-return destination or use existing shipment info
      const returnShipmentConfigEnabled = hasFeature(config, 'feature.shipments.returnShipmentConfig')
      const {state} = smartId.parse(shipment.destination.id)
      const defaultLocalPP = getDefaultReturnDestination(returnShipmentConfigEnabled, state) || shipment.origin.id
      const defaultOrigin = shipment.destination.id
      const title = shipmentTitle(shipment, config, true, defaultLocalPP, defaultOrigin)
      if (!validateCanReturn || !returnBatches.length) {
        console.warn(`Can't create return shipment it's not matched with criteria`)
        return history.push(`/shipments/complete/${shipment.snapshotId}`)
      }
      this.setState({
        shipment: returnShipment,
        batches: returnBatches,
        title
      })
    }
  }

  handleCountUpdate = (batchId, inputQuantity, checked) => {
    const {batches} = this.state
    const updateBatchIndex = batches.findIndex(batch => batch.batchId === batchId)
    batches[updateBatchIndex].checked = checked
    batches[updateBatchIndex].quantity = inputQuantity
    this.setState({
      batches
    })
  }

  handleProceed = async () => {
    const {match: { params: { snapshotId } }, history, api} = this.props
    const {batches, shipment, comment} = this.state
    const formattedDate = formatDate(new Date(), 'snapshotId')
    try {
      const counts = batches.reduce((acc, batch) => Object.assign(acc, {
        [batch.batchId]: {
          quantity: batch.quantity,
          paymentType: batch.paymentType
        }
      }), {})
      const origin = await api.location.get(shipment.origin.id)
      const [firstSupplier] = await api.order.getSupplierIdsForLocation(origin, {planType: 'return'})
      let destination = firstSupplier
      if (!firstSupplier) {
        // set return destination to the original shipments origin
        destination = shipment.destination.id
      }
      const snapshotData = {
        origin: shipment.origin.id,
        destination,
        date: formattedDate,
        shipmentTypeId: 'routine',
        counts,
        comment,
        parentDocId: snapshotId,
        isAutomaticReturnShipment: true,
        orderId: shipment.orderId,
        routeId: shipment.routeId
      }
      const snapshot = await api.shipment.createSnapshot(snapshotData)
      history.push(`/shipments/complete/${snapshot.id}`)
    } catch (error) {
      console.error('Failed to create return shipment', error)
      toast({
        title: 'Error when creating return shipment',
        type: 'error'
      })
      history.push('/shipments/list')
    }
  }

  onCommentChange = ({target: { value: comment }}) => {
    this.setState({comment})
  }

  render () {
    const {
      config
    } = this.props

    const {
      shipment,
      batches,
      comment,
      title
    } = this.state

    const fractionChecked = getFractionChecked(batches)
    if (!shipment) {
      return <Loading />
    }
    return <ReturnShipment
      title={title}
      shipment={shipment}
      config={config}
      batches={batches}
      onCommentChange={this.onCommentChange}
      handleCountUpdate={this.handleCountUpdate}
      handleProceed={this.handleProceed}
      fractionChecked={fractionChecked}
      comment={comment}
    />
  }
}

const mapStateToProps = (state, props) => {
  const newState = updateCurrentMasterData(state, 'masterDataShipments')

  const {products} = newState.masterData
  const productsById = products.byId
  const shipmentIsComplete = isShipmentComplete(props.shipment)
  const batches = getShipmentBatches(props.shipment, productsById)

  return {
    ...props,
    batches,
    shipmentIsComplete,
    pickList: newState.pickList,
    products
  }
}

const mapDispatchToProps = {
  updateShipment,
  loadPickList,
  createSnapshot
}

const withHOCs = flowRight(
  withUser,
  withApi,
  withConfig,
  withMasterData,
  withShipment,
  connect(mapStateToProps, mapDispatchToProps)
)

export default withHOCs(ReturnShipmentContainer)
