module.exports = saveChanges
module.exports.draftAdjustments = draftAdjustments

const get = require('lodash/get')
const find = require('./shipment-find')
const draftAdjustmentId = require('./tools/draft-adjustment')
const toShipmentId = require('./tools/to-shipment-id')
const VectorClock = require('vectorclock')
const { SHIPMENT_VERSIONS } = require('./constants')

const updatedChanges = (oldChanges, updates, now, shipmentBeforeChanges) => {
  const updated = Object.assign({}, oldChanges)
  Object.keys(updates).forEach(batchId => {
    const update = updates[batchId]
    updated[batchId] = updated[batchId] || {}

    let changeQty
    const oldChangeDocQty = get(oldChanges, `${batchId}.quantity`, 0)
    const oldShipmentQty = get(shipmentBeforeChanges, `counts.${batchId}.quantity`, 0)

    const notChangingQuantity = (update.quantity === oldShipmentQty || typeof update.quantity !== 'number')
    if (notChangingQuantity) {
      // When just checking/unchecking an item, don't update the quantity on the changeDoc
      changeQty = oldChangeDocQty
    } else if (typeof update.quantity === 'number') {
      // Ex: shipment: 113, changeDoc: -10, new amount 123, should be 0
      // 123 - 113 - -10 = 0
      // 105 - 113 - -10 = -18
      changeQty = update.quantity - oldShipmentQty + oldChangeDocQty
    }

    updated[batchId] = {
      quantity: changeQty,
      timestamp: now,
      checked: !!update.checked, // defaults undefined to false :-) a quick type cast
      paymentType: update.paymentType
    }
    if ('removed' in update) {
      updated[batchId].removed = update.removed
    }
  })
  return updated
}

const findShipment = (state, id, options) => {
  const shipmentId = toShipmentId(id)
  return find(state, shipmentId, options)
}

const getAllChanges = (state, snapshotId) => {
  return state.dal.shipment.getByPrefix(state, `${snapshotId}:change:`)
    .then(result => result.rows.map((row) => row.doc))
}

const updateVectorClock = (state, id, doc) => {
  return getAllChanges(state, id)
    .then(allChanges => {
      const vectors = allChanges.map(change => change.clock)
        .filter(x => x)
      const totalVector = vectors.reduce(VectorClock.merge, {})
      const updatedVector = VectorClock.increment(totalVector, state.device.id)
      // this prop name is tied to the sort implementation
      // https://github.com/mixu/vectorclock/blob/master/index.js#L37
      doc.clock = updatedVector

      return doc
    })
}

async function saveChanges (state, id, changes) {
  const changeDocId = `${id}:change:${state.device.id}`
  await saveChangeDoc(state, id, changeDocId, changes)
  return findShipment(state, id)
    .then(shipments => shipments[0])
}

async function draftAdjustments (state, id, changes) {
  // Changes to received shipments are written
  // as adjustments, so we can change multiple times
  // and track each one separately,.
  const changeDocId = draftAdjustmentId(id)
  await saveChangeDoc(state, id, changeDocId, changes)
  return findShipment(state, id, { pendingAdjustments: true })
    .then(shipments => shipments[0])
}

async function saveChangeDoc (state, id, changeDocId, changes) {
  const now = new Date().toISOString(0)
  const [shipmentBeforeChanges] = await findShipment(state, id, { pendingAdjustments: true })

  return state.dal.shipment.getById(state, changeDocId)
    .then(doc => {
      doc.updatedAt = now
      doc.changes = updatedChanges(doc.changes, changes, now, shipmentBeforeChanges)
      return doc
    })
    .catch(error => {
      /* istanbul ignore if */
      if (error.status !== 404) {
        throw error
      }

      return {
        _id: changeDocId,
        type: 'change',
        version: SHIPMENT_VERSIONS.VERSION_2,
        createdBy: state.user.name,
        createdAt: now,
        updatedAt: now,
        changes: updatedChanges({}, changes, now, shipmentBeforeChanges)
      }
    })
    .then(doc => updateVectorClock(state, id, doc))
    .then(doc => state.dal.shipment.write(state, doc))
}
