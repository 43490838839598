module.exports = updateSnapshot

const docToVanRecord = require('./tools/doc-to-van-record')
const {isInvalidSnapshot} = require('./validate/snapshot')
const { SHIPMENT_VERSIONS } = require('./constants')

async function updateSnapshot (state, options) {
  const {
    snapshotId,
    counts,
    shipmentTypeId,
    planningType,
    createdAt,
    createdBy,
    _rev,
    funderId,
    routeId,
    orderId,
    otpKey,
    externalShipmentId,
    dryRun = false,
    programId
  } = options

  const doc = {
    _id: snapshotId,
    type: 'snapshot',
    version: SHIPMENT_VERSIONS.VERSION_2,
    counts,
    shipmentTypeId,
    planningType,
    programId,
    funderId,
    routeId,
    orderId,
    createdAt: createdAt,
    createdBy: createdBy,
    updatedAt: new Date(),
    updatedBy: state.user.name,
    _rev: _rev
  }

  if (otpKey) doc.otpKey = otpKey

  if (externalShipmentId) doc.externalShipmentId = externalShipmentId

  const validationErrors = isInvalidSnapshot(doc)
  if (validationErrors) {
    throw new Error(validationErrors)
  }

  if (dryRun) return doc

  const { rev } = await state.dal.shipment.write(state, doc)
  doc._rev = rev

  return docToVanRecord(doc, state.user.location.id)
}
