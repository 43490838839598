const get = require('lodash/get')
const keyBy = require('lodash/keyBy')

function isOrphanShipment (shipment) {
  if (!shipment || (shipment.status !== 'arrived' && shipment.status !== 'received')) {
    return false
  } else {
    // If we have a new, sent, or pre-advice in our history, we're not an orphan.
    return !Object.keys(shipment.history).find(snapshotId => {
      const status = snapshotId.split('status:')[1].split(':')[0]
      return (status === 'new' || status === 'sent' || status === 'pre-advice')
    })
  }
}

function canShipmentBeMarkedVoid (shipment) {
  return (shipment.status === 'new' || shipment.status === 'pre-advice' || isOrphanShipment(shipment))
}

/**
 * HEADS UP: this does not return batch expiries as we're not starting with
 * them in shelf life. But if needed, just add master data batch info.
 * @param shipment a store api shipment entity
 * @param productsById master data object of products
 * @return a list of batches with shipment counts and product info
 */
function getShipmentBatches (shipment, productsById) {
  // Get array of batches on shipment with master data product prop
  return Object.keys(shipment.counts)
    .map(batchId => {
      const [productId] = batchId.split(':manufacturer')
      const product = productsById[productId]
      const batch = {batchId, ...shipment.counts[batchId], ...product}
      const total = getOriginalQuantity(shipment, batchId)
      return {...batch, id: batchId, total, picked: batch.quantity}
    })
    // sort by product name
    .sort((a, b) => a.name - b.name)
}

// This expects to be working on only a status new snapshot
// see picklist reducer logic if this ever changes for SL
function getOriginalQuantity (shipment, batchId) {
  const prevSnapshot = shipment.history[shipment.snapshotId]
  return get(prevSnapshot, `counts.${batchId}.quantity`, 0)
}

function transformToReturnBatch (returnShipment, batches = []) {
  return batches.reduce((acc, val) => {
    const item = returnShipment.counts[val.id]
    const quantity = val.total - val.picked
    if (item && quantity > 0) {
      acc.push({
        paymentType: item.paymentType,
        hideManifest: true,
        name: val.name,
        productId: val._id,
        id: val._id,
        total: val.total,
        checked: item.checked,
        quantity: quantity,
        picked: item.quantity,
        batchId: val.batchId
      })
    }
    return acc
  }, [])
}

const transformReceivedCount = (receivedCount, batches = []) => {
  const batchesByProduct = keyBy(batches, 'batchId')
  return Object.keys(receivedCount).reduce((acc, batch) => {
    const productBatch = batchesByProduct[batch]
    acc[batch] = receivedCount[batch]
    // Quantity that was sent from warehouse
    // We perform an automatic return with the difference
    acc[batch].quantity = productBatch.total
    return acc
  }, {})
}

module.exports = {
  isOrphanShipment,
  canShipmentBeMarkedVoid,
  getShipmentBatches,
  getOriginalQuantity,
  transformToReturnBatch,
  transformReceivedCount
}
