module.exports = `
with
new_invoice as (
  select
    $1 as shipment_id,
    fsid as location_fsid,
    uuid as location_id
  from avocado.data_location l
  where l.uuid = $2 and l.classification not in ('Supplier', 'Pack Point')
),
shipment as (
  select
    s.id,
    least(o.created_at, s.created_at) as order_created_at,
    s.shipment_id,
    s.order_id,
    s.status,
    s.is_automatic_return,
    lo.uuid as origin_location_id,
    lo.market_id as origin_market_id,
    ld.uuid as destination_location_id,
    ld.market_id as destination_market_id,
    ni.location_id
  from new_invoice ni
  left join avocado.data_shipment s
         on s.shipment_id = ni.shipment_id
        and (s.origin_id = ni.location_fsid or s.destination_id = ni.location_fsid)
  left join avocado.data_order o on o.order_id = s.order_id
  left join avocado.data_location lo on lo.fsid = s.origin_id
  left join avocado.data_location ld on ld.fsid = s.destination_id
),
shipment_line as (
  select
    s.order_id,
    s.shipment_id,
    -- Use the translated sku for 'new' and 'sent' cross market shipments.
    -- Received shipments already have translated skus and because skus for the same
    -- product can change over time, we only want to translate when necesarry.
    -- For auto returns we would not need to translate on new and sent only on received
    case 
      when origin_market_id <> destination_market_id 
        then case 
          when s.is_automatic_return = true and s.status in ('new', 'sent') 
            then sl.sku
          when s.status in ('new', 'sent') 
            then cm.sku
          else sl.sku
        end
      else sl.sku
    end as sku,
    max(sl.order_invoicing_type) as order_invoicing_type,
    max(s.order_created_at) as date,
    -- We deduct what was returned to accurately invoice client
    sum(case when s.is_automatic_return = true
      then sl.amount * -1
      else sl.amount
    end) as quantity,
    0 as bonus
  from shipment s
  inner join avocado.data_shipmentline sl on sl.shipment_id = s.id
  left join lateral (
    -- Translate SKU between source and target market
    select ps2.old_sku_id as sku
    from avocado.data_productsku ps1
    inner join avocado.data_productsku ps2
            on ps2.market_id = destination_market_id
           and ps2.presentation_id = ps1.presentation_id
    where ps1.old_sku_id = sl.sku
    -- Prefer non discontinued non deleted products by sorting those to the bottom
    -- This works, because false < true
    order by ps2._deleted nulls first,
             ps2.visibility = 'Discontinued'
    limit 1
  ) cm on true
  where (s.status = 'received'
         and sl.order_invoicing_type in ('prepaid', 'postpaid', 'pay_on_delivery'))
    or (s.status = 'sent' and sl.order_invoicing_type = 'pay_on_delivery')
    -- We want to process auto returns the moment they're created i.e new snapshot, this is also maintained in shipments-queue
    or (s.status = 'new' and s.is_automatic_return = true and sl.order_invoicing_type in ('prepaid', 'postpaid', 'pay_on_delivery'))
  group by 1, 2, 3
),
existing_line as (
  select
    s.shipment_id,
    il.order_id,
    il.sku,
    max(il.order_invoicing_type) as order_invoicing_type,
    sum(il.quantity) as quantity,
    max(il.date) as date
  from shipment s
  left join avocado.data_invoice_line il on (
      il.shipment_id = s.shipment_id
      -- Prepaid invoices initially don't have a shipment id, only
      -- a order id, but the shipment sync will backfill the shipment id.
      or (il.shipment_id is null and il.order_id = s.order_id)
  )
  left join avocado.data_invoice i on i.id = il.invoice_id
  where i.location_id = s.location_id
    and il.event_type = 'delivery'
  group by 1, 2, 3
),
diff_line as (
  select
    'delivery' as event_type,
    coalesce(el.order_invoicing_type, sl.order_invoicing_type) as order_invoicing_type,
    coalesce(sl.sku, el.sku) as sku,
    coalesce(sl.date, el.date) as date,
    coalesce(sl.quantity, 0) - coalesce(el.quantity, 0) as quantity,
    0 as bonus,
    coalesce(sl.order_id, el.order_id) as order_id,
    coalesce(sl.shipment_id, el.shipment_id) as shipment_id
  from shipment_line sl
  full outer join existing_line el on el.sku = sl.sku
  where coalesce(sl.quantity, 0) - coalesce(el.quantity, 0) <> 0
)
select
  (select to_jsonb(i) from (
    select
      location_id as "locationId"
    from new_invoice
  ) i) as invoice,
  coalesce(
    (select jsonb_agg(l) from (
      select
        event_type as "eventType",
        order_invoicing_type as "orderInvoicingType",
        sku,
        date,
        quantity,
        coalesce(bonus, 0) as bonus,
        order_id as "orderId",
        shipment_id as "shipmentId"
      from diff_line
    ) l),
    '[]'::jsonb
  ) as lines
`
